<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="fundraising"
      itemscope
      itemtype="http://schema.org/WebPage"
    >

    <div class="masonry-wrapper team">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Our Team</h1>
        </div>            
      </div>      
    </div>

    <div class="masonry-wrapper sub-team">
      <div class="masonry home-services">
        <div class="overlay masonry-item image" >
        </div>
        <div class="overlay masonry-item text">
          <div class="masonry-title">
            <h2>
              One call. One solution
            </h2>
            <h3 class="sub-heading">
              No property is too big or too small
            </h3>
            <p>
              We take care of all maintenance, repairs and installations for commercial properties throughout Staffordshire. From heating and plumbing services to air conditioning and electrical installations - we take care of property needs for warehouses, leisure facilities, tourist attractions, hotels and much more. No property is too big or too small for Ignite Facilities. We also provide emergency call out services offering the one solution to fit all. 
              
              
            </p>
          </div>
        </div>              
      </div>
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">
        <!-- Meet The Team Tiles -->
        <Section class="image-text colour1 floors about-team-back" style="padding-top: 0em;">
          <Grid class="" style="padding-top: 0em;" center middle>
            <Column :below-laptop-width="100" :column-width="100" below-laptop-text-center>
              <div class="masonry-wrapper">
                <div class="team-grid">

                  <!-- Chris Brass-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/Chris-Low-res.jpg" alt="Chris Brass" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3>Chris Brass</h3>
                        <h4>Managing Director</h4>
                        <p class="client-service"> 
                          Chris has lived and breathed the industry all of his adult life. From a young age, Chris assisted his father in his own gas and heating business after gaining qualifications in both the gas and electrical sectors.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Rachel Brass-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/Rachel-Low-res.jpg" alt="Rachel Brass" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3> Rachel Brass</h3>
                        <h4>Director & Company Secretary</h4>
                        <p class="client-service"> 
                          Rachel joined Ignite Facilities to support the operations of the business and the services which we provide. Rachel has also gained more than 15 years experience working in the trade.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Nicole Lawrence-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/Nicole-Low-res.jpg" alt="Nicole Lawrence" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3>Nicki Lawrence</h3>
                        <h4>Commercial Accounts & Admin</h4>
                        <p class="client-service"> 
                          Nicki has worked in our office for many years and has experience working for many national companies before. Nicki handles invoicing, liasing with client jobs, diary planning, and works as the first point of call.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Dan Rose-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/Dan-Low-res.jpg" alt="Dan Rose" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3>Dan Rose</h3>
                        <h4>Commercial Services Supervisor</h4>
                        <p class="client-service"> 
                          Dan has been working in the industry for more than seven years. Dan oversees installations, repairs and servicing to all of our customers appliances.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Tony Hilton-->
                  <div class="overlay masonry-team-item">
                    <div >                  
                      <img src="img/ignite/team/Tony-Low-res.jpg" alt="Tony Hilton of Ignite Facilities" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3> Tony Hilton</h3>
                        <h4>Fitter & Commercial Plumber</h4>
                        <p class="client-service"> 
                          Tony brings with him 20 years experience working within the heating and plumbing industry, and has a vast experience in multiple trades which allows Tony to problem solve with ease.
                        </p> 
                      </div>                                   
                    </div>
                  </div>
                
                  <!-- Wayne Worthington-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/Wayne-Low-res.jpg" alt="Wayne Worthington" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3> Wayne Worthington</h3>
                        <h4>Commercial Service Engineer</h4>
                        <p class="client-service"> 
                          Wayne is another one of our multi-skilled Commercial Service Engineers who has more than eight years within the industry. Wayne has worked on various large scale projects, and has specialisation is combustion equipment.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Dave Mountford-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/Dave-Low-res.jpg" alt="Dave Mountford" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3>Dave Mountford</h3>
                        <h4>Commercial Service Engineer</h4>
                        <p class="client-service"> 
                          Dave is one of our highly skilled service engineers. Dave has extensive knowledge in the industry after starting as apprentice after leaving school, and has over 20 years providing service level support to our clients.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Join The Team-->
                  <div class="overlay masonry-team-item">
                    <div>                  
                      <img src="img/ignite/team/join-the-team.png" alt="Join The Team" class="masonry-content team-image">
                      <div class="team-project-title">
                        <h3> Join The Team</h3>
                        <p class="client-service"> 
                          If you would like to work in a family run facilities management business, apply to Ignite Facilities today.  
                        </p>
                      </div>
                    </div>
                  </div>

                  

                </div>
              </div>
            </Column>
          </Grid>
        </Section>

        <CaseStudies/>
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "team",
  metaInfo: {
    title: 'Our Team',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Our Team | Ignite Facilities'
      }
    ]
  },
  components: {
    CaseStudies,
  }
};
</script>

<style lang="scss">
.fundraising {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
.section-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

.section {
  width: 100%;
  display: table;
}

.section-image,
.section-text {
  display: table-cell;
  height: 300px;
}

.section-image {
  // background-image: url("../img/grant-money.jpg");
  background-size: cover;
  width: 50%;
  border-radius: 5%;
}

.section-text {
  padding: 60px;
  width: 50%;
}

.funding-image {
  height: 18rem;
  border-radius: 5%;
}
.service {
  text-align: center;
  border: 1px solid #5d4242;
  padding: 15px;
  background-color: rgb(0 0 0 / 48%);
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 18%;
}
</style>
